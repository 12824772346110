<template>
  <div>
    <div id="map"></div>
    <div class="menu">
      <div class="item" v-for="item in typeList" :key="item.value" :class="selected === item.value ? 'active' : ''"
        @click="onSelect(item.value)">
        {{ item.name }}
      </div>
    </div>

    <ThisDetail :detail="detail" />
  </div>
</template>
<script>
import ThisDetail from "./components/detail.vue";
import request from "@/api/request";
import { typeList } from "./data";

export default {
  components: {
    ThisDetail,
  },

  data() {
    return {
      typeList,
      list: [],
      detail: {},
      selected: "",
    };
  },

  watch: {
    selected() {
      this.list = [];
      this.detail = {};
      if (this.selected === "liquid_level") {
        this.getLiquid();
      } else {
        this.getFlow();
      }
    },
    list: {
      handler() {
        if (this.list.length > 0) {
          this.detail = this.list[0];
          this.setMarker();
        }
      },
      deep: true,
    },
    detail: {
      handler() {
        if (this.detail.id) {
          this.setMarker();
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.initMap();
    this.selected = "liquid_level";
  },

  beforeDestroy() {
    this.markers?.forEach((item) => {
      item.off("click", this.onMarkerClick);
    });
    this.map?.clearMap();
    this.map?.destroy();
  },

  methods: {
    initMap() {
      // 初始化地图
      // 引用AMAP地图组件
      const { AMap } = window;
      if (!AMap) {
        return;
      }

      // 创建地图
      var map = new AMap.Map("map", {
        center: [119.056657, 33.599268],
        zoom: 16,
        zooms: [16, 20],
        mapStyle: "amap://styles/52faa27d18984923cceae498330eceb1",
        WebGLParams: {
          preserveDrawingBuffer: true,
        },
      });

      this.map = map;
    },

    onSelect(key) {
      this.selected = key;
    },

    getLiquid() {
      request({
        url: "/model-analysis/pipe/device/query/realtime/type/liquidLevel",
      }).then((res) => {
        console.log("res", res);
        if (Array.isArray(res)) {
          this.list = res;
        }
      });
    },

    getFlow() {
      request({
        url: "/model-analysis/pipe/device/query/realtime/type/flowMeter",
      }).then((res) => {
        console.log("res", res);
        if (Array.isArray(res)) {
          this.list = res;
        }
      });
    },

    setMarker() {
      // 创建地图标记
      // 先清除原来地图上的标记
      this.markers?.forEach((item) => {
        item.off("click", this.onMarkerClick);
      });
      this.markers = [];
      this.map?.clearMap();

      if (this.selected == "liquid_level") {

        const liquid_levels = this.list.filter((item) => {
          return (item.type == "liquidLevel" && item.realtime !== undefined);
        });
        const markers = liquid_levels.map((item) => {
          let content = "";


          if (item.id === this.detail.id) {

            content = `
                <div style="background-color: #000;white-space: nowrap;border-radius: 100px;border: 2px solid #f00;line-height: 32px;font-size: 14px;display: flex;align-items: center; padding: 0 8px ">
                      <img src='/icon/liquid-active.png' style="height: 18px;margin-right: 8px;border-radius: 50%;border: 2px solid #f00"  />
                    <span style='color: #f00'>${item.name}：${item.realtime.liquidLevel} m</span>
              </div>
              `;
          } else {
            content = `
                    <div style="background-color: #000;white-space: nowrap;border-radius: 100px;border: 2px solid #ff6e21;line-height: 32px;font-size: 14px;display: flex;align-items: center; padding: 0 8px ">
                      <img src='/icon/liquid-active.png' style="height: 18px;margin-right: 8px"  />
                    <span>${item.name}：${item.realtime.liquidLevel} m</span>
              </div>
          `;
          }


          const marker = new window.AMap.Marker({
            position: [item.x, item.y],
            content,
            extData: item,
          });

          marker.on("click", this.onMarkerClick);
          return marker;
        });

        this.map.add(markers);
        this.markers = markers;
      } else {
        /** 在流速页面只显示 */
        const flow_meters = this.list.filter((item) => {
          return (item.type == "flowMeter" && item.realtime !== undefined);
        });
        const markers = flow_meters.map((item) => {
          let content = "";



          if (item.id === this.detail.id) {
            content = `
                    <div style="background-color: #000;white-space: nowrap;border-radius: 100px;border: 2px solid #f00;line-height: 32px;font-size: 14px;display: flex;align-items: center; padding: 0 8px ">
                      <img src='/icon/flow-active.png' style="height: 18px;margin-right: 8px;border-radius: 50%;border: 2px solid #f00"  />
                    <span style='color: #f00'>${item.realtime.flowRate}：m/s</span>
              </div>
              `;
          } else {
            content = `
                 <div style="background-color: #000;white-space: nowrap;border-radius: 100px;border: 2px solid #ffabaf;line-height: 32px;font-size: 14px;display: flex;align-items: center; padding: 0 8px ">
                      <img src='/icon/flow-active.png' style="height: 18px;margin-right: 8px"  />
                    <span>${item.name}：${item.realtime.flowRate}m/s</span>
              </div>
          `;
          }

          const marker = new window.AMap.Marker({
            position: [item.x, item.y],
            content,
            extData: item,
          });

          marker.on("click", this.onMarkerClick);
          return marker;
        });

        this.map.add(markers);
        this.markers = markers;
      }
    },

    onMarkerClick(e) {
      console.log(e);
      this.detail = e.target._opts.extData;
    },
  },
};
</script>


<style lang="less" scoped>
#map {
  position: fixed;
  height: 105vh;
  width: 100vw;
  left: 0;
  top: 0;
}

.menu {
  position: fixed;
  top: 12vh;
  left: 2vw;
  background-color: #000;
  border-radius: 8px;
  padding: 1vh;
  display: flex;
  align-items: center;
  cursor: pointer;

  .item {
    padding: 4px 8px;
  }

  .active {
    background-color: var(--theme-color);
    color: #fff;
    border-radius: 2px;
  }
}
</style>