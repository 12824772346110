<template>
  <div class="detail">
    <div class="header">
      <div class="title">{{ detail.name }}</div>
    </div>

    <div class="content" style="padding-top: 2vh">
      <div class="item">
        <div class="label">编号：</div>
        <div class="value">{{ detail.code }}</div>
      </div>
      <div class="item">
        <div class="label">名称：</div>
        <div class="value">{{ detail.name }}</div>
      </div>

      <div class="item">
        <div class="label">液位：</div>
        <div class="value">
          {{
        realTimeData.liquidLevel != undefined
          ? realTimeData.liquidLevel + "m"
          : "--"
      }}
        </div>
      </div>
      <div class="item">
        <div class="label">流速</div>
        <div class="value">
          {{
          realTimeData.flowRate != undefined
            ? realTimeData.flowRate + "m/s"
            : "--"
        }}
        </div>
      </div>
      <div class="item">
        <div class="label">流量</div>
        <div class="value">
          {{
          realTimeData.instantaneous != undefined
            ? realTimeData.instantaneous + "m3/h"
            : "--"
        }}
        </div>
      </div>
      <div class="item">
        <div class="label">温度：</div>
        <div class="value">
          {{
          realTimeData.temperature != undefined
            ? realTimeData.temperature + " ℃"
            : "--"
        }}
        </div>
      </div>

      <div class="item">
        <div class="label">更新时间：</div>
        <div class="value">{{ realTimeData.ts ? realTimeData.ts : "--" }}</div>
      </div>
    </div>

    <div style="padding: 1.5vh 1vw">统计数据</div>
    <a-space style="padding-bottom: 4vh; padding: 0 1vw">
      <a-range-picker size="small" style="width: 13vw" v-model="selectedDate" :allowClear="false" />
      <a-select size="small" placeholder="指标" style="width: 8vw" v-model="queryOption">
        <a-select-option v-for="item in queryOptions" :key="item.value">
          {{ item.name }}
        </a-select-option>
      </a-select>
      <a-select placeholder="间隔" size="small" style="width: 6vw" v-model="interval">
        <a-select-option v-for="item in statInterval" :key="item.value">
          {{ item.name }}
        </a-select-option>
      </a-select>
      <a-button type="primary" size="small" @click="query">查询</a-button>
      <a-button size="small" @click="reset">重置</a-button>
    </a-space>

    <div style="padding-top: 1vh"></div>
    <Chart id="history-chart" :list="chartList" :rainList="rainList" />
    <div style="padding-top: 1vh"></div>
    <Table :list="tableList" :loading="loading" />
  </div>
</template>

<script>
import Chart from "./chart.vue";
import Table from "./table.vue";
import moment from "moment";
import request from "@/api/request";
import { mapGetters } from "vuex";

export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    Chart,
    Table,
  },

  data() {
    return {
      selectedDate: [moment().subtract(1, "months"), moment()],
      queryOption: "liquidLevel",
      queryOptions: [
        // 这里是TOPIC卡片上历史数据查询的的
        {
          name: "液位 m",
          value: "liquidLevel",
        },
        {
          name: "流速 m/s",
          value: "flowRate",
        },
        {
          name: "温度 ℃",
          value: "temperature",
        },
        {
          name: "实时流量 V",
          value: "instantaneousFlow",
        },
      ],
      interval: "1d",

      loading: false,
      chartList: [],
      rainList: [],
      tableList: [],

      realTimeData: {},
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    statInterval() {
      return this.findDataDict("statInterval");
    },
  },

  watch: {
    detail: {
      handler() {
        if (this.detail.sn) {
          this.getRealTimeData();
          this.getHistoryData();
        }
      },
      deep: true,
    },
  },

  methods: {
    getHistoryData() {
      this.loading = true;

      request({
        url: "/data-service/dev/stat",
        method: "post",
        data: {
          devType: this.detail.type,
          beginDate: this.selectedDate[0].format("YYYY-MM-DD") + " 00:00:00",
          endDate: this.selectedDate[1].format("YYYY-MM-DD") + " 00:00:00",
          eids: [this.detail.sn],
          interval: this.interval,
        },
      })
        .then((res) => {
          if (Array.isArray(res)) {
            this.tableList = res;

            this.chartList = res.map((item) => {
              return {
                ts: item.ts,
                name: this.queryOptions.find(
                  (item) => item.value === this.queryOption
                ).name,
                value: item[this.queryOption],
              };
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });

      request({
        url: "/model-analysis/pipe/device/query/type/rainFall",
      }).then((res) => {
        console.log("rain config", res);

        if (Array.isArray(res) && res.length > 0) {
          if (res[0].sn) {
            request({
              url: "/data-service/dev/stat",
              method: "post",
              data: {
                devType: "rainFall",
                beginDate:
                  this.selectedDate[0].format("YYYY-MM-DD") + " 00:00:00",
                endDate:
                  this.selectedDate[1].format("YYYY-MM-DD") + " 00:00:00",
                eids: [res[0].sn],
                interval: this.interval,
              },
            }).then((res) => {
              console.log("flow detail", res);
              if (Array.isArray(res)) {
                this.rainList = Object.freeze(
                  res.map((item) => {
                    return {
                      ts: item.ts,
                      name: "雨量",
                      value: item.rainfall,
                    };
                  })
                );
              }
            });
          }
        }
      });
    },

    query() {
      this.getHistoryData();
    },
    reset() {
      this.selectedDate = [moment().subtract(1, "months"), moment()];
      this.queryOption = "liquidLevel";
      this.interval = "1d";
      this.getHistoryData();
    },

    getRealTimeData() {
      request({
        url: "/data-service/dev/realTime",
        method: "post",
        data: {
          devType: this.detail.type, // 获取类型
          eids: [this.detail.sn],
        },
      }).then((res) => {
        this.realTimeData = res || {};
      });
    },
  },
};
</script>


<style lang="less" scoped>
.detail {
  position: fixed;
  top: 11vh;
  height: 87vh;
  overflow-y: auto;
  right: 1vw;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.7);

  width: 50vw;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  &>.header {
    background-color: var(--theme-color);
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 1vw;
    line-height: 4vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .content {
    padding: 0 1vw;
    display: flex;
    flex-wrap: wrap;
    gap: 1vh;

    .item {
      width: 33%;
      display: flex;
      align-items: center;

      .label {
        min-width: 4em;
      }

      .value {
        color: var(--theme-color);
      }
    }
  }
}
</style>