<template>
  <div style="padding: 0 1vw;">
    <a-table bordered :data-source="list" :loading="loading" size="small">
      <a-table-column title="液位(米)" align="center">
        <template slot-scope="text">
          <div :title="text" class="text-overflow">
            {{ typeof text.liquidLevel === 'number' ? text.liquidLevel :
      "--" }}
          </div>
        </template>
      </a-table-column>
      <a-table-column title="温度(℃)" align="center">
        <template slot-scope="text">
          <div :title="text" class="text-overflow">
            {{ typeof text.temperature === 'number' ? text.temperature :
      "--" }}
          </div>
        </template>
      </a-table-column>
      <a-table-column title="流速(米/秒)" align="center">
        <template slot-scope="text">
          <div :title="text" class="text-overflow">
            {{ typeof text.flowRate === 'number' ? text.flowRate :
      "--" }}
          </div>
        </template>
      </a-table-column>
      <a-table-column title="流量(立方米/时)" align="center">
        <template slot-scope="text">
          <div :title="text" class="text-overflow">
            {{ typeof text.instantaneousFlow === 'number' ? text.instantaneousFlow :
      "--" }}
          </div>
        </template>
      </a-table-column>
      <a-table-column title="更新时间" align="center">
        <template slot-scope="text">
          <div :title="text" class="text-overflow">{{ text.ts }}</div>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>